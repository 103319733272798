import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import "aos/dist/aos.css";
import Aos from "aos";
import logo from "./logo.png";
import "./ComingSoon.css";

const ComingSoon = () => {
  const [days, setDays] = useState(10);
  const [hours, setHours] = useState(14);
  const [minutes, setMinutes] = useState(33);
  const [seconds, setSeconds] = useState(20);

  useEffect(() => {
    Aos.init();
    const countdownInterval = setInterval(() => {
      // Logic to decrement countdown timer (placeholder)
      setSeconds((prev) => (prev > 0 ? prev - 1 : 59));
      if (seconds === 0) {
        setMinutes((prev) => (prev > 0 ? prev - 1 : 59));
        if (minutes === 0) {
          setHours((prev) => (prev > 0 ? prev - 1 : 23));
          if (hours === 0) {
            setDays((prev) => (prev > 0 ? prev - 1 : 0));
          }
        }
      }
    }, 1000);

    return () => clearInterval(countdownInterval);
  }, [seconds, minutes, hours, days]);

  return (
    <div className="coming-soon">
      <header className="header">
        <div className="logo-container">
          <img src={logo} alt="Logo" className="logo" />
        </div>
      </header>

      <main className="main-content">
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          className="coming-soon-text"
        >
          <h1>Exciting Things Are Coming Your Way!</h1>
          <p>Our team is crafting something extraordinary just for you. Stay tuned!</p>
        </motion.div>

        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.8, ease: "easeInOut" }}
          className="timer"
        >
          <h2>Launching In:</h2>
          <div className="countdown">
            <div>
              <span>{days}</span>
              <small>Days</small>
            </div>
            <div>
              <span>{hours}</span>
              <small>Hours</small>
            </div>
            <div>
              <span>{minutes}</span>
              <small>Minutes</small>
            </div>
            <div>
              <span>{seconds}</span>
              <small>Seconds</small>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.5, delay: 0.5 }}
          className="newsletter"
        >
          {/* <h3>Be the First to Know</h3>
          <form className="newsletter-form">
            <input
              type="email"
              placeholder="Enter your email"
              className="newsletter-input"
            />
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="newsletter-button"
            >
              Notify Me
            </motion.button>
          </form> */}
        </motion.div>
      </main>

      <footer className="footer">
        <p>&copy; 2024 Affiliate Ritual. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default ComingSoon;
